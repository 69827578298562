import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined'

const SystemSettingMenu = {
    id: 'SystemSettingRoot',
    title: 'System Setting',
    type: 'group',
    children: [
        {
            id: 'systemSettingScheduleSort',
            title: 'Schedule Sort',
            type: 'item',
            url: '/systemSetting/scheduleSort',
            icon: SettingsSuggestOutlinedIcon
        },
        {
            id: 'systemSettingCreditType',
            title: 'Account Type',
            type: 'item',
            url: '/systemSetting/creditType',
            icon: SettingsSuggestOutlinedIcon
        },
        {
            id: 'systemSettingPagePermission',
            title: 'Page Permission',
            type: 'item',
            url: '/systemSetting/pagePermitTable',
            icon: SettingsSuggestOutlinedIcon
        },
        {
            id: 'moduleNavAclTable',
            title: 'Module Permission',
            type: 'item',
            url: '/systemSetting/moduleNavAclTable',
            icon: SettingsSuggestOutlinedIcon
        },
        {
            id: 'userWageTable',
            title: 'Staff Wage Setting',
            type: 'item',
            url: '/systemSetting/userWageTable',
            icon: SettingsSuggestOutlinedIcon
        }
    ]
}
export default SystemSettingMenu